import React, { Component } from "react";
import styled from "styled-components";
import SecurityModal from "components/common/SecurityModal";
import SecurityTextInput from "components/common/SecurityTextInput";
import { connect } from "react-redux";
import { logonActions } from "actions/logonAction";
import { registrationActions } from "actions/registrationDetailAction";
import WarningMsg from "components/common/WarningMsg";
import { FormattedMessage, injectIntl } from "react-intl";
import { envConfig } from "env-config";

const fieldValidationRegEx = {
    UKLN: /^[a-zA-Z0-9]{10,20}$/,
    birthDay: /0[1-9]|[1-2]\d|3[0-1]/,
    birthMonth: /0[1-9]|1[0-2]/,
    birthYear: /^[1-2]\d{3}$/
};

const mapStateToProps = state => {
    return {
        terms: state.logon.terms,
        disableContinue: state.registrationDetails.isLoading
    };
};
const birthDateOrder = {
    "en-US": ["birthMonth", "birthDay", "birthYear"],
    default: ["birthDay", "birthMonth", "birthYear"]
};
const mapDispatchToProps = dispatch => {
    return {
        logonFeachTerms: () => dispatch(logonActions.logonFeachTerms()),
        isLoading: payload =>
            dispatch(registrationActions.validateDetailsIsLoading(payload))
    };
};

class EnterDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enableAcceptButton: true,
            registrationWarningMsg: "",
            count: 0,
            firstNameValid: null,
            lastNameValid: null,
            UKLNValid: null,
            birthDayValid: null,
            birthMonthValid: null,
            birthYearValid: null,
            firstName: "",
            lastName: "",
            UKLN: "",
            birthDay: "",
            birthMonth: "",
            birthYear: ""
        };
        this.verifyAttempts = 0;
        this.countDown = null;
        if (props.storeThis) {
            props.storeThis("EnterDetails", this);
        }
        this.dateBirthSort =
            birthDateOrder[envConfig.locale] || birthDateOrder["default"];
    }

    componentDidMount() {
        window.tealiumHub.PT009();
        this.props.logonFeachTerms();
    }

    startTimer = reDuration => {
        let startCount = reDuration ? reDuration : this.state.count;
        if (startCount > 0) {
            startCount -= 1;
            this.setState(previousState => {
                return {
                    ...previousState,
                    count: startCount,
                    enableAcceptButton: false
                };
            });
        }

        this.countDown = setInterval(() => {
            startCount -= 1;
            if (startCount > 0) {
                this.setState({ count: startCount });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        count: startCount,
                        registrationWarningMsg: "",
                        enableAcceptButton: true
                    };
                });
                clearInterval(this.countDown);
                this.verifyAttempts = 2;
            }
        }, 1000);
    };

    setVerifyAttempts = () => {
        this.verifyAttempts += 1;
        if (this.state.enableAcceptButton && this.verifyAttempts === 3) {
            this.startTimer(60);
        }
    };

    submit = () => {
        window.tealiumHub.ET016("registration.submit");
        this.validate();
    };

    setRegistrationWarningMsg = res => {
        this.setState(previousState => {
            return {
                ...previousState,
                registrationWarningMsg: res.statusText
            };
        });
    };

    failCallback = res => {
        this.setVerifyAttempts();
        if (
            res.statusText.indexOf("not recognise") >= 0 ||
            res.statusText.indexOf("我们未识别出") >= 0 ||
            res.statusText.indexOf("我們未能辨析") >= 0 ||
            res.statusText.indexOf("reconnaissons pas") >= 0
        ) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    registrationWarningMsg:
                        "enterDetails.warning.aria.infoError"
                };
            });
        }
    };

    onChange = event => {
        const { target } = event;
        const { value, name } = target;
        const autoFocus = () => {
            const nextIndex = this.dateBirthSort.indexOf(name) + 1;
            const nextInputComp = this.dateBirthSort[nextIndex];
            if (nextInputComp) {
                this.birthDateRefs[nextInputComp].getSelfFocused();
            }
        };
        switch (name) {
            case "birthDay":
            case "birthMonth":
                if (value.length >= 2) {
                    autoFocus();
                }
                break;
            case "birthYear":
                {
                    if (value.length >= 4) {
                        autoFocus();
                    }
                }
                break;
            default:
                console.log("default");
        }
        this.setState((previousState, currentProps) => {
            return {
                ...previousState,
                [name]: value,
                registrationWarningMsg: ""
            };
        });
    };
    componentWillUnmount = () => {
        if (this.props.disableContinue) {
            this.props.isLoading(false);
        }
    };
    validateDateOfBirth = (name, value) => {
        switch (name) {
            case "birthDay": {
                if (value > 31 || value.length > 2) {
                    return false;
                }
                break;
            }
            case "birthMonth": {
                if (value > 12 || value.length > 2) {
                    return false;
                }
                break;
            }
            case "birthYear": {
                if (value > new Date().getFullYear() || value.length > 4) {
                    return false;
                }
                break;
            }
            default:
                console.log("default");
        }
        return true;
    };

    validate = () => {
        this.setState((previousState, currentProps) => {
            let newState = { ...previousState };
            const allFieldArr = Object.keys(fieldValidationRegEx);
            allFieldArr.forEach(item => {
                const result = fieldValidationRegEx[item].test(
                    previousState[item]
                );
                newState = {
                    ...newState,
                    [`${item}Valid`]: result
                };
            });
            const alldFieldIsValid = allFieldArr.every(
                item => newState[`${item}Valid`]
            );
            if (alldFieldIsValid) {
                const {
                    firstName,
                    lastName,
                    UKLN,
                    birthDay,
                    birthMonth,
                    birthYear
                } = this.state;
                const formInput = window.com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
                    {
                        firstname: firstName,
                        surname: lastName,
                        dateofbirth: `${birthYear}-${birthMonth}-${birthDay}`,
                        avaloqid: UKLN
                    }
                );
                this.props.isLoading(true);
                this.acceptFN(formInput);
            } else {
                console.log(
                    "Fields have error::Will not send the user data to transmit."
                );
                this.setVerifyAttempts();
                newState = {
                    ...newState,
                    registrationWarningMsg:
                        "enterDetails.warning.aria.infoError"
                };
            }
            return newState;
        });
    };

    birthDateRefs = {};

    render() {
        const birthdateComps = {
            birthDay: (
                <SecurityTextInput
                    customClassName="enterdetails-birthdate-input"
                    placeHolder={this.props.intl.formatMessage({
                        id: "enterDetails.label.dateOfBirth.day"
                    })}
                    name="birthDay"
                    hideLabel={true}
                    label="enterDetails.label.dateOfBirth.dayLabel"
                    id="birthDay"
                    title="enterDetails.label.dateOfBirth.dayLabel"
                    ariaDescribedby="birthDate_error"
                    onChange={this.onChange}
                    validateFromParent={this.validateDateOfBirth}
                    onFocus={touched => {
                        if (!touched) {
                            window.tealiumHub.ET015("dob day");
                        }
                    }}
                    onRef={comp => (this.birthDateRefs.birthDay = comp)}
                />
            ),
            birthMonth: (
                <SecurityTextInput
                    customClassName="enterdetails-birthdate-input"
                    placeHolder={this.props.intl.formatMessage({
                        id: "enterDetails.label.dateOfBirth.month"
                    })}
                    name="birthMonth"
                    hideLabel={true}
                    id="birthMonth"
                    ariaDescribedby="birthDate_error"
                    label="enterDetails.label.dateOfBirth.monthLabel"
                    title="enterDetails.label.dateOfBirth.monthLabel"
                    onChange={this.onChange}
                    validateFromParent={this.validateDateOfBirth}
                    onFocus={touched => {
                        if (!touched) {
                            window.tealiumHub.ET015("dob month");
                        }
                    }}
                    onRef={comp => (this.birthDateRefs.birthMonth = comp)}
                />
            ),
            birthYear: (
                <SecurityTextInput
                    customClassName="enterdetails-birthdate-input"
                    placeHolder={this.props.intl.formatMessage({
                        id: "enterDetails.label.dateOfBirth.year"
                    })}
                    hideLabel={true}
                    name="birthYear"
                    ariaDescribedby="birthDate_error"
                    label="enterDetails.label.dateOfBirth.yearLabel"
                    id="birthYear"
                    title="enterDetails.label.dateOfBirth.yearLabel"
                    onChange={this.onChange}
                    validateFromParent={this.validateDateOfBirth}
                    onFocus={touched => {
                        if (!touched) {
                            window.tealiumHub.ET015("dob year");
                        }
                    }}
                    onRef={comp => (this.birthDateRefs.birthYear = comp)}
                />
            )
        };

        return (
            <EnterDetailsContainer>
                <SecurityModal
                    title="enterDetails.title"
                    ariaTitle="enterDetails.title"
                    customizedFooter={
                        this.state.count ? (
                            <p className="count--down">
                                <FormattedMessage id="enterDetails.label.count.previousPart" />
                                {this.state.count}
                                <FormattedMessage id="enterDetails.label.count.afterPart" />
                            </p>
                        ) : null
                    }
                    dialogWidth="454px"
                    onConfirmationClick={this.submit}
                    confirmationBtnTitle="registration.submit"
                    confirmationBtnDisabled={!this.state.enableAcceptButton}
                    disabledButton={this.props.disableContinue || false}
                    hideButton={this.state.count ? true : false}
                >
                    <div className="enterdetails-content">
                        <p
                            className="enterdetails-content-p1"
                            aria-label={this.props.intl.formatMessage({
                                id: "enterDetails.aria.label.subTitle"
                            })}
                        >
                            <span aria-hidden="true">
                                {this.props.intl.formatMessage({
                                    id: "enterDetails.label.subTitle"
                                })}
                            </span>
                        </p>

                        <div aria-live="assertive">
                            {this.state.registrationWarningMsg ? (
                                <WarningMsg
                                    warningMsg={
                                        this.state.registrationWarningMsg
                                    }
                                />
                            ) : null}
                        </div>
                        <SecurityTextInput
                            customClassName="enterdetails-ulk-input"
                            name="UKLN"
                            label="enterDetails.label.clientId"
                            id="ukln"
                            ariaDescribedby="UKLN_error"
                            title="enterDetails.label.clientId"
                            onChange={this.onChange}
                            hideLabel={false}
                            onFocus={touched => {
                                if (!touched) {
                                    window.tealiumHub.ET015("client ID");
                                }
                            }}
                        />

                        <SecurityTextInput
                            customClassName="enterdetails-input"
                            name="firstName"
                            label="enterDetails.label.firstName"
                            hideLabel={false}
                            id="firstName"
                            ariaDescribedby="firstName_error"
                            title="enterDetails.label.firstName"
                            onChange={this.onChange}
                            onFocus={touched => {
                                if (!touched) {
                                    window.tealiumHub.ET015("first name");
                                }
                            }}
                        />

                        <SecurityTextInput
                            customClassName="enterdetails-input"
                            name="lastName"
                            label="enterDetails.label.lastName"
                            hideLabel={false}
                            id="lastName"
                            ariaDescribedby="lastName_error"
                            title="enterDetails.label.lastName"
                            onChange={this.onChange}
                            onFocus={touched => {
                                if (!touched) {
                                    window.tealiumHub.ET015("last name");
                                }
                            }}
                        />

                        <p className="enterdetails-birthdate-label enterdetails-input-label">
                            <FormattedMessage id="enterDetails.label.dateOfBirth" />
                        </p>

                        {birthdateComps[this.dateBirthSort[0]]}

                        <span className="enterdetails-birthdate-dash"> - </span>

                        {birthdateComps[this.dateBirthSort[1]]}

                        <span className="enterdetails-birthdate-dash"> - </span>

                        {birthdateComps[this.dateBirthSort[2]]}
                    </div>
                </SecurityModal>
            </EnterDetailsContainer>
        );
    }

    startSession = () => {
        console.log("startSession");
    };

    promiseFormInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    setPromiseHandlers(acceptFN, rejectFN) {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    }

    endSession() {
        console.log("endSession");
    }

    promiseRecoveryForError = () => {
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
        );
    };

    onContinue = payload => {
        this.props.isLoading(false);
        if (payload) {
            if (payload.reason === "USER_NOT_MATCHED") {
                this.failCallback({
                    errorStatus: 204,
                    statusText: this.props.intl.formatMessage({
                        id: "enterDetails.warning.continueFail"
                    })
                });
            }
        }
    };
    onError = () => {
        window.globalHistory.push({
            pathname: "/globalError/noConnection?step=022",
            state: { errorCode: "noConnection" }
        });
    };

    onFatal = () => {
        console.log("onFatal");
    };
}

const EnterDetailsContainer = styled.div`
    .contentmodal--content {
        .enterdetails-content {
            .enterdetails-ulk-prefix {
                display: inline-block;
                border: solid 1px;
                line-height: 41px;
                -webkit-logical-height: 45px;
                vertical-align: bottom;
                border-right: 0px;
                color: grey;
                width: 69px;
                text-align: center;
                font-size: 14px;
                color: #333;
                border-color: grey;
            }
            .enterdetails-input-label {
                margin-top: 20px;
                font-size: 16px;
                line-height: 19px;
            }
            .enterdetails-input {
                margin-top: 10px;
                width: 240px;
            }
            .enterdetails-ulk-input {
                margin-top: 10px;
                display: inline-block;
                width: 240px;
            }
            .enterdetails-firstname-label {
                margin-top: 20px;
            }
            .enterdetails-birthdate-input {
                width: 68px;
                display: inline-block;
                font-size: 14px;
                input::-webkit-input-placeholder {
                    color: #333;
                }
            }
            .error-comp {
                margin-top: 2px;
                .error-text {
                    display: inline-block;
                    margin-left: 5px;
                    width: 215px;
                }
                .icon--triangle-error {
                    font-size: 20px;
                    vertical-align: top;
                }
            }
        }
        .enterdetails-content-p1 {
            margin-top: 30px;
            color: #252525;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
        }
        .enterdetails-birthdate-dash {
            margin: 0 2px;
        }
    }
    .contentmodal--footer {
        margin-top: 30px;
        text-align: right;
    }
    p {
        margin-bottom: 10px;
        &.count--down {
            text-align: left;
        }
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EnterDetails));

import React, { Component } from "react";
import styled from "styled-components";
import { history } from "util/history";
import SecurityModal from "../common/SecurityModal";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { logonActions } from "actions/logonAction";

const mapStateToProps = state => {
    return {
        gvLogon: state.logon
    };
};
const mapDispatchToProps = dispatch => {
    return {
        switchToGVLogin: payload =>
            dispatch(logonActions.switchToGVLogin(payload))
    };
};

class GvError extends Component {
    render() {
        return (
            <div>
                <GvErrorContianer>
                    <SecurityModal
                        titleIcon={
                            <i className="icon exclamation triangle error gv-icon-margin" />
                        }
                        onConfirmationClick={() => {
                            history.go(-2);
                        }}
                        confirmationBtnTitle="gvResult.label.backToPremier"
                    >
                        <GvResultContainer>
                            <div className="gvresult-content">
                                {this.props.intl.formatMessage({
                                    id: "gvResult.error.content"
                                })}
                            </div>
                        </GvResultContainer>
                    </SecurityModal>
                </GvErrorContianer>
                <form
                    id="link-profile-form"
                    action={this.props.gvLogon.url}
                    method="POST"
                    style={{ display: "none" }}
                >
                    <label>
                        <input type="text" name="SSO_TOKEN_ID" />
                    </label>
                    <input type="submit" value="Submit" onClick={this.submit} />
                </form>
            </div>
        );
    }
}

const GvErrorContianer = styled.div`
    .gv-icon-margin {
        margin-top: 38px;
        font-size: 24px !important;
    }
`;
const GvResultContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 38px;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    .success {
        color: #4faf00;
    }
    .gv-icon-margin {
        margin-top: 38px;
        font-size: 24px !important;
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(GvError));

import React, { Component } from "react";
import styled from "styled-components";
import { color } from "util/color";
import { languageActions } from "actions/languageAction";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
const mapStateToProps = state => {
    return {
        alreadyInitMLContainer: state.languageSession.alreadyInitMLContainer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideSelectOption: isHideLanguageSelect =>
            dispatch(languageActions.hideSelectOption(isHideLanguageSelect))
    };
};
const NoticeContent = props => {
    return (
        <div className="notice-container">
            <div className="anchor" id="starttotop"></div>
            <div className="notice-title">
                <h1>
                    {props.lang.formatMessage({
                        id: "cookies.title"
                    })}
                </h1>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.subTitle"
                    })}
                </p>
                <span className="title-hr"></span>
            </div>
            <div className="page-nav-container">
                <nav aria-label="You are here" className="page-nav">
                    <ol className="page-nav-ol" role="list">
                        <li className="page-nav-li-1">
                            <div className="li-link-container">
                                <a className="page-link" href="#/logon">
                                    {props.lang.formatMessage({
                                        id: "cookies.nav.first"
                                    })}
                                </a>
                                <i className="icon angle right"></i>
                            </div>
                        </li>

                        <li className="page-nav-li-2">
                            {" "}
                            {props.lang.formatMessage({
                                id: "cookies.nav.second"
                            })}
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="notice-introduction">
                <h3>
                    {props.lang.formatMessage({
                        id: "cookies.descript.title"
                    })}
                </h3>
                <ul className="notice-list-view-all">
                    <li>
                        <a
                            onClick={() =>
                                props.scrollToAnchor("whatarecookies")
                            }
                            data-testid="ccmLink"
                        >
                            {props.locale &&
                            (props.locale === "en-LU" ||
                                props.locale === "fr-LU")
                                ? props.lang.formatMessage({
                                      id: "cookies.what.title.fr"
                                  })
                                : props.lang.formatMessage({
                                      id: "cookies.what.title"
                                  })}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() => props.scrollToAnchor("cookiesweuse")}
                            data-testid="ccmLink"
                        >
                            {props.lang.formatMessage({
                                id: "cookies.we.use.title"
                            })}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() =>
                                props.scrollToAnchor("cookiepreferences")
                            }
                            data-testid="ccmLink"
                        >
                            {props.lang.formatMessage({
                                id: "cookies.how.we.title"
                            })}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() =>
                                props.scrollToAnchor("browsersettings")
                            }
                            data-testid="ccmLink"
                        >
                            {props.lang.formatMessage({
                                id: "cookies.browser.setting.title"
                            })}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() => props.scrollToAnchor("ourwebsites")}
                            data-testid="ccmLink"
                        >
                            {props.lang.formatMessage({
                                id: "cookies.our.website.title"
                            })}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() => props.scrollToAnchor("privacy")}
                            data-testid="ccmLink"
                        >
                            {props.lang.formatMessage({
                                id: "cookies.privacy.title"
                            })}
                        </a>
                    </li>
                </ul>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.predescript.1"
                    })}
                </p>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.predescript.2"
                    })}
                </p>
                <button
                    className={` primary btn--confirm `}
                    onClick={() => props.popUpModal()}
                    data-testid="ccmButton"
                >
                    {props.lang.formatMessage({
                        id: "cookies.setting.btn"
                    })}
                </button>
            </div>
            {middleContent(props)}
            <div
                className="back-to-top"
                onClick={() => props.scrollToAnchor("starttotop")}
                data-testid="ccmBackToTop"
            >
                <a>
                    <span className="link">
                        {props.lang.formatMessage({
                            id: "cookies.back.top"
                        })}
                    </span>
                </a>
                &nbsp;<i className="icon arrow up "></i>
            </div>
        </div>
    );
};

const middleContent = props => {
    return (
        <div>
            <div className="notice-whatarecookies">
                <div className="anchor" id="whatarecookies"></div>
                <div className="title-wrapper">
                    <h2>
                        {props.lang.formatMessage({
                            id: "cookies.what.title"
                        })}
                    </h2>
                </div>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.what.content"
                    })}
                </p>
            </div>
            {longTableContent(props)}
            <div className="noitce-cookiepreferences">
                <div className="anchor" id="cookiepreferences"></div>
                <h2>
                    {props.lang.formatMessage({
                        id: "cookies.remember.title"
                    })}
                </h2>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.remember.content1"
                    })}
                </p>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.remember.content2"
                    })}
                </p>
            </div>
            <div className="noitce-browsersettings">
                <div className="anchor" id="browsersettings"></div>
                <h2>
                    {props.lang.formatMessage({
                        id: "cookies.setting.title"
                    })}
                </h2>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.setting.conten1"
                    })}
                </p>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.setting.conten2"
                    })}
                </p>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.setting.conten3"
                    })}
                </p>
                {props.locale &&
                (props.locale === "en-LU" || props.locale === "fr-LU")
                    ? props.lang.formatMessage({
                          id: "cookies.setting.conten4"
                      })
                    : ""}
            </div>
            <div className="notice-ourwebsites">
                <div className="anchor" id="ourwebsites"></div>
                <h2>
                    {props.lang.formatMessage({
                        id: "cookies.our.site.title"
                    })}
                </h2>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.our.site.content"
                    })}
                    <strong></strong>
                </p>
            </div>
            <div className="notice-privacy">
                <div className="anchor" id="privacy"></div>
                <h2>
                    {props.lang.formatMessage({
                        id: "cookies.privacy.title"
                    })}
                </h2>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.privacy.content"
                    })}
                </p>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.privacy.content.1"
                    })}{" "}
                    <a
                        href="https://www.privatebanking.hsbc.com/privacy-notice/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="privacyNotice"
                    >
                        {props.lang.formatMessage({
                            id: "cookies.policy.link"
                        })}
                    </a>
                </p>
            </div>
        </div>
    );
};

const longTableContent = props => {
    return (
        <div>
            <div className="notice-cookiesweuse">
                <div className="anchor" id="cookiesweuse"></div>
                <h2>
                    {props.lang.formatMessage({
                        id: "cookies.use.title"
                    })}
                </h2>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.content.1"
                    })}
                </p>
                <h3>
                    {props.lang.formatMessage({
                        id: "cookies.use.subtitle.1"
                    })}
                </h3>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.content.2"
                    })}{" "}
                </p>
                <h4>
                    {props.lang.formatMessage({
                        id: "cookies.use.subtitle.2"
                    })}
                </h4>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.content.3"
                    })}{" "}
                </p>
                <h4>
                    {props.lang.formatMessage({
                        id: "cookies.use.subtitle.3"
                    })}
                </h4>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.content.4"
                    })}
                </p>
                <table>
                    <caption>
                        <p>
                            {props.lang.formatMessage({
                                id: "cookies.use.table.content"
                            })}
                        </p>
                    </caption>
                    <thead>
                        <tr>
                            <th scope="col">
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.title.left"
                                })}
                            </th>
                            <th scope="col">
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.title.right"
                                })}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.right.1"
                                })}
                            </td>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.left.1"
                                })}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.right.2"
                                })}
                            </td>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.left.2"
                                })}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.right.3"
                                })}
                            </td>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.left.3"
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h4>
                    {" "}
                    {props.lang.formatMessage({
                        id: "cookies.use.subtitle.optional"
                    })}
                </h4>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.optional.content.1"
                    })}
                </p>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.optional.content.2"
                    })}
                </p>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.optional.content.3"
                    })}
                </p>
                <h4>
                    {" "}
                    {props.lang.formatMessage({
                        id: "cookies.use.subtitle.5"
                    })}
                </h4>
                <p>
                    {props.lang.formatMessage({
                        id: "cookies.use.content.7"
                    })}
                </p>
                <table className="desktop">
                    <caption>
                        <p>
                            {props.lang.formatMessage({
                                id: "cookies.use.content.8"
                            })}
                        </p>
                    </caption>
                    <thead>
                        <tr>
                            <th scope="col">
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.3.title.left"
                                })}
                            </th>
                            <th scope="col">
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.3.title.right"
                                })}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.3.content.left"
                                })}
                            </td>
                            <td>
                                {props.lang.formatMessage({
                                    id: "cookies.use.table.3.content.right"
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {bottomTable(props)}
            </div>
        </div>
    );
};

const bottomTable = props => {
    return (
        <div>
            <h3>
                {" "}
                {props.lang.formatMessage({
                    id: "cookies.use.subtitle.6"
                })}
            </h3>
            <p>
                {props.lang.formatMessage({
                    id: "cookies.use.content.9"
                })}
            </p>

            <table className="desktop">
                <caption>
                    <p>
                        {props.lang.formatMessage({
                            id: "cookies.use.table.content"
                        })}
                    </p>
                </caption>
                <thead>
                    <tr>
                        <th scope="col">
                            {props.lang.formatMessage({
                                id: "cookies.support.market.table.title.left"
                            })}
                        </th>
                        <th scope="col">
                            {props.lang.formatMessage({
                                id: "cookies.support.market.table.title.right"
                            })}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {props.lang.formatMessage({
                                id: "cookies.use.table.4.left.1"
                            })}
                        </td>
                        <td>
                            {props.lang.formatMessage({
                                id: "cookies.use.table.4.right.1"
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {props.lang.formatMessage({
                                id: "cookies.use.table.4.left.2"
                            })}
                        </td>
                        <td>
                            {props.lang.formatMessage({
                                id: "cookies.use.table.4.right.2"
                            })}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

class CookieNotice extends Component {
    constructor(props) {
        super(props);
    }
    scrollToAnchor = anchorName => {
        if (anchorName) {
            const anchorEle = document.getElementById(anchorName);
            if (anchorEle && anchorEle.scrollIntoView) {
                anchorEle.scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                });
            }
        }
    };
    componentDidMount() {
        this.props.alreadyInitMLContainer && this.props.hideSelectOption(false);
    }
    componentWillUnmount() {
        const settingModal = document.getElementById("__tealiumGDPRcpPrefs");
        if (settingModal) {
            settingModal.parentNode.removeChild(settingModal);
        }
    }
    popUpModal = () => {
        console.log("pop up start");
        try {
            window.utag.gdpr.showConsentPreferences();
        } catch {
            console.error("pop up function not ready");
        }
    };

    render() {
        return (
            <NoticeWrapper className="notice-wrapper">
                <div className="left"></div>
                <div className="center">
                    <NoticeContent
                        scrollToAnchor={this.scrollToAnchor}
                        popUpModal={this.popUpModal}
                        lang={this.props.intl}
                        locale={this.props.intl.locale}
                    ></NoticeContent>
                </div>
                <div className="right"></div>
            </NoticeWrapper>
        );
    }
}

const NoticeWrapper = styled.div`
.page-nav-container{
    margin:40px 0px;
}
.page-nav-ol{
    list-style-type: none;
    display: flex;
    padding-inline-start: 0px;
  
}
.page-nav-li-1{
    
    a{
        text-decoration: none;
        font-size: 16px;
    }
    .icon{
        color: #af000d;
        font-size: 20px;
        margin-left: 6px;
    
    }
    .right{
        width:10px;
    }
}
.page-nav-li-2{
    font-weight: 700;
    margin-top: 2px;
    margin-left: 10px;
    font-size: 1rem;
}
}
.title-hr{
    border-top:1px solid #d7d8d6;
    height:1px;
    display: block;
}
    .notice-list-view-all li {
        cursor: pointer;
    }
    .back-to-top {
        cursor: pointer;
        margin-bottom: 40px;
    }
    h2 {
        font-weight: 400;
        line-height: 1.2;
        font-size: 1.5625rem;
        text-align: left;
        padding-left: 15px;
        border-left: 5px solid #ec3a3a00;
        color: #333;
        display: block;
        line-height: 1.2;
        position: relative;
    }
    h2:after {
        right: auto;
        left: -5px;
        background: #db0011;
        content: "";
        display: block;
        width: 5px;
        position: absolute;
        height: 63%;
        top: 0.24em;
        bottom: 0.24em;
    }
    h2:not(:first-child) {
        margin-top: 40px;
    }
    h3 {
        font-weight: 300;
        font-size: 1.5625rem;
        margin: 20px 0px;
    }
    h4 {
        font-size: 1.25rem;
        font-weight: 300;
    }
    table {
        background: #fff;
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    table th {
        border: 1px solid #b6b7b6;
        word-wrap: break-word;
        background: #ededed;
        font-size: 0.875rem;
        text-align: left;
        color: #4d6474;
        font-weight: bold;
        line-height: 1.2;
    }
    table td {
        text-align: left;
        font-size: 0.875rem;
        border: 1px solid #b6b7b6;
        word-wrap: break-word;
    }
    td,
    th {
        vertical-align: top;
    }
    caption {
        text-align: left;
    }
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    border-image: linear-gradient(to top, rgba(255, 255, 255, 0), #d7d8d6);
    border-image-slice: 10;
    width: 100%;
    display: flex;
    a {
        text-decoration: underline;
        color: inherit;
    }
    .center {
        width: 50%;
        padding: 2.625rem 0rem 0rem 0rem;
    }
    .left {
        width: 15%;
    }
    .right {
        width: 35%;
    }
    @media (max-width: 960px) {
        .center {
            width: 70%;
        }
        .left {
            width: 15%;
        }
        .right {
            width: 15%;
        }
    }
    @media (max-width: 320px) {
        .center {
            width: 90%;
        }
        .left {
            width: 5%;
        }
        .right {
            width: 5%;
        }
        table th {
            padding: 0.25rem 0.25rem 0.25rem 0.25rem;
        }
        table td {
            padding: 0.25rem 0.25rem 0.25rem 0.25rem;
        }
    }
    @media (min-width: 960px) {
        padding: 3.75rem 0rem 0rem 0rem;
        h2,
        h3 {
            font-size: 1.75rem;
        }
        table th {
            padding: 1.25rem 1.25rem 1.25rem 1.25rem;
        }
        table td {
            padding: 1.25rem 1.25rem 1.25rem 1.25rem;
        }
        h2:after {
            top: 0.22em;
            bottom: 0.25em;
        }
        .title-hr{
            margin:3.75rem 0rem 1.25rem 0rem ;
        }
    }
    @media (min-width: 480px) {
        padding: 3.375rem 0rem 0rem 0rem;
        table th {
            padding: 1.125rem 1.125rem 1.125rem 1.125rem;
        }
        table td {
            padding: 1.125rem 1.125rem 1.125rem 1.125rem;
        }
        .title-hr{
            margin:3.75rem 0rem 1.125rem 0rem ;
        }
    }
    @media (min-width: 320px) {
        padding: 0rem 0rem 0rem 0rem;
    }
    .privacyNotice{
        color: #006efd !important;
        font-weight:300 !important;
    }
    .btn--confirm {
        margin: 40px 0px;
        display: ${props => (props.confirmationBtnDisabled ? "none" : "block")};
        ${props => {
            if (props.confirmationBtnStyle) {
                return props.confirmationBtnStyle;
            }
            return `
        @media (max-width: 414px) {
            cursor: pointer;
            padding: 14px 20px 16px 20px;
            background-color: ${color.hsbc_red};
            color: ${color.white};
            font-weight: normal;
            line-height: 16px;
            border: none;
            width: ${props.hideBorderTop ? "100%" : "auto"};
        }
        @media (min-width: 415px) {
            cursor: pointer;
            padding: 14px 20px 16px 20px;
            background-color: ${color.hsbc_red};
            color: ${color.white};
            font-weight: normal;
            line-height: 16px;
            border: none;
            width: ${props.hideBorderTop ? "40%" : "auto"};
        }
        `;
        }};
    }
`;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CookieNotice));

export default `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"HSBC Univers Next Regular";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
h4
	{mso-style-link:"Heading 4 Char";
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-indent:21.0pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.Heading4Char
	{mso-style-name:"Heading 4 Char";
	mso-style-link:"Heading 4";
	font-family:"Times New Roman",serif;
	font-weight:bold;}
p.a-par16r-rw-all, li.a-par16r-rw-all, div.a-par16r-rw-all
	{mso-style-name:a-par16r-rw-all;
	margin-right:0cm;
	margin-bottom:7.5pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"HSBC Univers Next Regular";
	color:#333333;}
span.visuallyhidden7
	{mso-style-name:visuallyhidden7;
	border:none windowtext 1.0pt;
	padding:0cm;}
span.link24
	{mso-style-name:link24;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
.MsoChpDefault
	{font-size:11.0pt;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=ZH-CN link=blue vlink="#954F72">

<div class=WordSection1>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Online
Privacy Statement</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC is committed to protecting your privacy when you use HSBC
Digital Services. This U.S. Online Privacy Statement (&quot;Statement&quot;)
explains how we collect, use, share, and safeguard your information when you
use our Digital Services.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC = HSBC Bank USA, N.A., its banking and non-banking U.S.
affiliates and subsidiaries and service providers (collectively, &quot;HSBC
USA&quot;, &quot;we&quot;, &quot;our&quot;).</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>You/Your = Customers and visitors (including their agents and
representatives).</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>Digital Services = Our websites, mobile applications, channels,
pages, or accounts we operate on social media sites (e.g. Twitter), and any
other online interactions you may have with us.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>Use of our Digital Services = Visiting any Digital Services
owned and controlled by us, receiving our e-mails and viewing our ads on
third-party sites and other devices that link to this Statement; using our
Digital Services to access your accounts, our Click-to-Chat service, conduct
online transactions, etc.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>When you visit our Digital Services, read our e-mails or
interact with us on third-party sites, you are consenting to the terms of this
Statement, except where a different online and/or mobile statement is posted
indicating that it is intended to take the place of this Statement.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>This policy is intended for residents of the United States and
its territories. If you are not a resident of the United States or its
territories, your information may be transferred or processed outside of your
home country, including in the United States and its territories.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>What
information we collect</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>We may collect basic types of information from you through
Digital Services as described below.</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&quot;Personal information&quot;&nbsp;refers to information that
identifies (whether directly or indirectly) a particular individual, such as
information you provide on our forms, surveys, remote deposit capture off your
device, applications or similar online fields. Examples may include your name,
postal address, unique device identifier, email address, telephone number,
Social Security number, date of birth or account information.</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&quot;Anonymous information&quot;&nbsp;means information that
cannot reasonably be used to identify a particular individual. Examples may
include information about your Internet browser, IP address, information collected
through tracking technologies, demographic information that you provide to us
(e.g., your household income) and aggregated or de-identified data.</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&quot;Location information&quot;&nbsp;means information that may
be collected by certain mobile applications that identifies your physical
location. This information may be collected from your mobile device's
location-aware features when you request certain services that are dependent on
your physical location.</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&quot;Biometric information&quot;&nbsp;means information such as your fingerprint,
voiceprint, or facial features that you choose to provide to us for
authentication and fraud prevention purposes.&nbsp; We will not collect your
biometric information without your permission.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>How
we use your information</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>We may use your online information or combine with other
information to:</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>personalize your Digital Services experience;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>respond to your inquiries and fulfill your requests;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>deliver marketing communications we believe may be of interest
to you;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>inform you about important information regarding Digital
Services, products or services, changes to terms, conditions, and policies
and/or other administrative information;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>allow you to apply for products or services (e.g., to prequalify
for a mortgage, apply for a credit card, or to open a retirement account,
investment account or other financial product), process transactions, and
evaluate your eligibility for such products or services;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>verify your identity, authenticate you to use our Digital
Services and verify your location</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>allow you to participate in surveys and other forms of market
research, sweepstakes, contests and similar promotions and to administer these
activities. Some of these activities have additional rules, which may contain
additional information about how Personal Information is used and shared;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>allow you to use Digital Services financial planning tools.
Information that you enter into one of these planning tools may be stored for
future access and use. You have the option not to save the information by not
using these tools or by not accepting the site cookies through your browser
which can limit the site functionality;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>perform activities such as data analysis, audits, usage trends
to determine the effectiveness of our campaigns and as input into improving
products and services and enhancing our Digital Services;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>improve risk control for fraud detection and prevention, to
comply with laws and regulations, and to comply with other legal process and
law enforcement requirements;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>allow you to utilize Digital Services features by granting us access
to information from your device such as contact lists, or geo-location when you
request certain services;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>use it in other ways as required or permitted by law or with
your consent.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>How
We Share Your Information</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>We may share your information with our affiliates and third
parties. For example, we may share your information with:</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC USA affiliated Digital Services and businesses in an effort
to bring you improved service across our family of products and services, when
permissible under relevant laws and regulations;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC USA third-party service providers who perform services for
us and help us operate our business;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>other companies to bring you co-branded services, products or
programs;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>third parties or affiliates in connection with a corporate
transaction, such as a sale, consolidation or merger of HSBC USA businesses;</span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>other third parties to comply with legal requirements such as
the demands of applicable subpoenas and court orders; to verify or enforce our
terms of use, our other rights, or other applicable policies; to address fraud,
security or technical issues; to respond to an emergency; or otherwise to
protect the rights, property or security of our customers or third parties.<br>
&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>For U.S. customers, we will use and share information that we
collect from or about you in accordance with the Customer Privacy Notice for
your account (see links on this page), which provides choices in the use and
sharing of information.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>As a global company, we may share your personal information
among HSBC USA affiliated Digital Services and businesses or service providers
that may be located outside your home country in order to provide you with the
highest quality products and services.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>We may share Anonymous or aggregated information with third
parties to help deliver products, services, and content which is better
tailored to the users of our Digital Services and for other purposes.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>HSBC
Mobile</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC offers you the ability to access some of our Digital
Services through our mobile app. When you use our mobile app we collect
information, such as your unique device identifier, screen resolution and other
settings, your geo-location (with your permission), and other data used for
anonymous analytical purposes. All HSBC mobile apps have their own terms of use
and privacy policies for reference.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Use
of Aggregation Tools through HSBC Digital Services</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>If you use any of our money management tools or other online
aggregation services, we collect information about the accounts and
relationships including registration information such as your name and e-mail
address. We also collect information about the accounts designated for the
money management tools, such as account balances, transactions, transaction
histories, e-mail information, bills, payment history and other account activity.
We will not disclose any information about you we obtain solely through money
management tools to any non-affiliate, except as needed to provide our money
management tools, or as required or permitted by law. We may use this
information to offer you other HSBC products and services that may be
beneficial to you. Refer to the money management tools section of the<b>&nbsp;</b></span><span
lang=EN-US><a
href="https://www.us.hsbc.com/online-banking/money-management-tools/frequent-asked-questions/"  target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Personal Internet Banking Terms and Conditions</span></a></span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&nbsp;for further details.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Cookies
&amp; Other Technologies</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&quot;Cookies&quot; are bits of electronic information that can
transfer to your hard drive, mobile device, or other device to keep records of
your use or visit to our Digital Services. We may use cookies to improve your
experiences when visiting or using our Digital Services. We may use cookies to
anonymously track interest in, and collect aggregate information when you use
or visit Digital Services. We do not use cookies to store or transmit any
Personal information. Temporary &quot;session&quot; cookies are also used to
facilitate customer navigation within our Digital Services during their visit.
&quot;Session&quot; cookies are deleted once you close your Internet browser.
We may also use &quot;persistent&quot; cookies that are retained on your
computer after your visit ends so we can identity your preferences and enhance
your future visits to our Digital Services.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&quot;Log files&quot; are files that anonymously log actions
that have occurred on a website. We may use log files to gather statistics
about your browsing habits and to assess overall digital activity, including
how many &quot;hits&quot; a particular web page is getting. Log files enable us
to track interest in specific promotions, troubleshoot technical concerns, and
provide content that may be of interest. We also use the log file entries for
our internal marketing and demographic studies, so we can improve the Digital
Service we provide to customers and visitors. Log files are used internally
only, are anonymous, and are not associated with any particular user, device,
computer, or browser.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&quot;Web beacons&quot; are small pieces of data that are
embedded in images on the pages of our Digital Services. We also use these
technical methods to analyze the traffic patterns on our websites, such as the
frequency with which consumers visit various parts of our websites. These
technical methods may involve the transmission of information either directly
to us or to another party authorized by us to collect information on our
behalf. We also use these technical methods in (HTML) emails that we send to
you to determine whether the recipients have opened those emails or clicked on
links in those emails. The information from these technical methods may be
collected in a form that is personally identifiable.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Behavioral
Advertising/Online Tracking</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>Information about your online activities, combined with other
information, may be used to select an advertisement for a product, service, or
offer that matches your interests. This advertising practice is called online
behavioral advertising (OBA) or interest-based advertising. We allow you to
exercise choice regarding the collection of information by third parties about
your online activities over time and across third-party websites or online
services for interest-based advertising purposes by going to these sites to
opt-out:&nbsp;</span><span lang=EN-US><a
href="https://www.networkadvertising.org/choices" target="_blank" rel="noopener noreferrer"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Network Advertising
Initiative </span></a></span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&nbsp;or&nbsp;</span><span lang=EN-US><a
href="https://www.aboutads.info/choices/" target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Digital Advertising Alliance
 </span></a></span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>. Opting out means you will still receive online advertising but
the advertising will not be tailored to your online behavior or usage.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>We do not respond to web browser 'do not track' signals at this
time. We await the result of work by the policy community and industry to
determine when such a response is appropriate and what form it should take.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>In order to develop our other Digital Services you may use, in
line with customer needs, we work with third parties to track usage within our
Digital Services. These third parties provide us with statistics showing which
pages are visited most frequently, how long visitors spend on each page, and
other and other anonymous Digital Services information. We use this information
to help us plan how to improve these services.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Third-Party
Links</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>Clicking on certain links within our Digital Services may take
you to other websites, or may display information on your computer screen or
device from other sites, which may not be maintained by HSBC. Such sites may
contain terms and conditions, privacy provisions, confidentiality provisions,
or other provisions that differ from the terms and conditions applicable to our
Digital Service. Links to other Internet services and websites are provided
solely for the convenience of users. A link to any service or site is not an
endorsement of any kind of the service or site, its content, or its sponsoring
organization.</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC AND/OR HSBC GROUP MEMBERS ASSUME NO RESPONSIBILITY OR LIABILITY
WHATSOEVER FOR THE CONTENT, ACCURACY, RELIABILITY OR OPINIONS EXPRESSED IN A
WEBSITE, TO WHICH OUR DIGITAL SERVICES ARE LINKED (A &quot;LINKED SITE&quot;)
AND SUCH LINKED SITES ARE NOT MONITORED, INVESTIGATED, OR CHECKED FOR ACCURACY
OR COMPLETENESS BY HSBC. IT IS THE RESPONSIBILITY OF THE USER TO EVALUATE THE
ACCURACY, RELIABILITY, TIMELINESS AND COMPLETENESS OF ANY INFORMATION AVAILABLE
ON A LINKED SITE. ALL PRODUCTS, SERVICES AND CONTENT OBTAINED FROM A LINKED
SITE ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, SECURITY, OR
ACCURACY.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Security</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>We take our responsibility to protect your information very seriously.
We use physical, technical, and procedural safeguards that comply with
applicable legal standards to secure your information from unauthorized access
and use, alteration, and destruction. Refer to the Security footer link below.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Social
Security Number Protection Policy</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC USA, in the normal course of business and as required by
law, may collect Social Security numbers to help establish, maintain and
service customer accounts. We implement reasonable measures to protect and
limit access to these Social Security numbers, as well as prohibit their
unlawful disclosure. Social Security numbers are classified as
&quot;Restricted&quot; information under the HSBC North America Information
Security Risk Policy and HSBC Information Classification Policy. As such, Social
Security numbers may be accessed by and disclosed to HSBC USA team members and
others only with a legitimate business &quot;need to know&quot; in accordance
with applicable laws and regulations.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Social
Media</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC USA may provide an experience on social media platforms
including, but not limited to, Facebook, Twitter, YouTube and LinkedIn that may
enable online sharing and collaboration among users who have registered to use
them. Please note that when visiting any official HSBC USA social media site,
you are subject to this Online Privacy Statement as well as the social media
platform's own terms and conditions. Refer to the&nbsp;</span><span lang=EN-US><a
href="https://www.us.hsbc.com/online-banking/social-media-terms-and-conditions/"  target="_blank" rel="noopener noreferrer"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>HSBC Social Media
Terms and Conditions</span></a></span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333'>&nbsp;for more information.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Children's
Online Privacy Protection Act</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>Our Digital Services are not directed to children. We do not
knowingly solicit or collect personal information from children under the age
13, without parental consent.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Updating
Your Information</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>Keeping your account information up-to-date is very important.
If you believe that your account information is incomplete or inaccurate,
please contact us through our Customer Service&nbsp;</span><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://www.us.hsbc.com/customer-service/contact-us/"  target="_blank" rel="noopener noreferrer">visit
Customer Service page</a></span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333'>. If you prefer, you may
call or write to us at the telephone numbers and addresses provided on your
account statements, or you may speak directly with a branch representative, or
your designated relationship manager.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Changes
to this Online Privacy Statement</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>If we make updates to our U.S. online privacy practices, we will
update this Statement with the changes and update the &quot;last updated&quot;
date posted at the bottom of the Statement. Any updates to the Statement become
effective when we post the updates on this site. Your use of our Digital
Services following any update to the Statement means that you accept the
updated Statement and consent to the use and sharing provisions identified in
the Statement.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Governing
Law</span></b></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>HSBC USA Digital Services are intended for customers and
visitors in the United States. When we process personal data and information
and personally identifying information in the United States, we follow United
States data protection and privacy regulations.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'><a
href="https://www.us.hsbc.com/content/dam/hsbc/us/docs/pdf/privacy-and-security-information.pdf" ><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>Click
here</span></a><span> Opens pdf version of the Privacy Statement.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'>
<a
href="https://www.us.hsbc.com/online-privacy-statement/#fn-01"  target="_blank"><sup><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'>†† view footnote
cross</span></sup></a></span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333'>&nbsp;to access a printable
version of the&nbsp;Online Privacy Statement.</span>
</p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'><b><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>†</span></b><span lang=EN-US style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:#333333'>&nbsp;Viewing PDF files require the use
of Adobe Acrobat Reader. If you do not already have Adobe Acrobat Reader, you can&nbsp;</span><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif'><a
href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">download</a></span><span lang=EN-US style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333'>&nbsp;it online. After
downloading the software, follow the instructions for installing the program.
If you prefer, you can always download the files now and open them offline
later.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
normal'><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>To download the Reader to your computer, visit </span><span
lang=EN-US><a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif'> https://get.adobe.com/reader/
</span></a></span><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>.</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Privacy
Policies</span></b></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/online-privacy-statement/#onlineprivacy"  target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt;text-decoration:none'>Online Privacy Statement</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/california-privacy-statement/"  target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt;text-decoration:none'>California Privacy</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a href="https://www.us.hsbc.com/privacy-notice/"  target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt;text-decoration:none'>Privacy Notice</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/private-banking-privacy-notice/"  target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt;text-decoration:none'>Private Banking Privacy
Notice</span><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333;position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&nbsp;&nbsp;</span></p>

<p class=MsoNormal style='line-height:normal'><b><span lang=EN-US
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333'>Security</span></b></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/security/protect-you/" target="_blank" rel="noopener noreferrer"><span style='font-size:
12.0pt;font-family:"Times New Roman",serif;color:#333333;position:relative;
top:-4.0pt;text-decoration:none'>Protect You</span><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333;position:relative;top:-4.0pt'>&nbsp;
</span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/security/report-fraud/" target="_blank"><span style='font-size:
12.0pt;font-family:"Times New Roman",serif;color:#333333;position:relative;
top:-4.0pt;text-decoration:none'>Report Fraud</span><span style='font-size:
12.0pt;font-family:"Times New Roman",serif;color:#333333;position:relative;
top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/security/protect-business/"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt;text-decoration:none'>Protect Your Business</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/security/protection-measures/" target="_blank" rel="noopener noreferrer"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt;text-decoration:none'>Protecting Consumers</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/security/other-resources/" target="_blank"><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt;text-decoration:none'>Other Security Resources</span><span
style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#333333;
position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/security/alerts/" target="_blank"><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333;position:relative;top:-4.0pt;
text-decoration:none'>Alerts</span><span style='font-size:12.0pt;font-family:
"Times New Roman",serif;color:#333333;position:relative;top:-4.0pt'>&nbsp; </span></a></span></p>

<p class=MsoListParagraph style='margin-left:18.0pt;text-indent:-18.0pt;
line-height:normal'><span lang=EN-US style='font-size:10.0pt;font-family:Symbol;
color:#333333'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US><a
href="https://www.us.hsbc.com/security/safeguard/" target="_blank"><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333;position:relative;top:-4.0pt;
text-decoration:none'>HSBC Safeguard</span><span style='font-size:12.0pt;
font-family:"Times New Roman",serif;color:#333333;position:relative;top:-4.0pt'>&nbsp;
</span></a></span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:normal'><span
lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
color:#333333'>Last updated: January 2020</span></p>

<p class=MsoNormal><span lang=EN-US style='font-size:12.0pt;line-height:107%;
font-family:"Times New Roman",serif'>&nbsp;</span></p>
</div>

</body>

</html>


`;

import React, { Component } from "react";
import styled from "styled-components";
import backgroundKaleidoTl from "assets/image/dsp_background_tl.png";
import backgroundKaleidoTp from "assets/image/dsp_background_tp.png";
import backgroundKaleidoDt from "assets/image/dsp_background_dt.png";
import backgroundKaleidoMb from "assets/image/dsp_background_mb.png";
import logoTc160 from "assets/image/logo_160_tc.svg";
import logoSc160 from "assets/image/logo_160_sc.svg";
import logoEn160 from "assets/image/logo_160_en.svg";
import { envConfig } from "env-config";
import { connect } from "react-redux";
import { languageActions } from "actions/languageAction";

const mapStateToProps = state => {
    return {
        selectedLang: state.languageSession.selectedLang
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateLanguage: lang => dispatch(languageActions.updateLanguage(lang))
    };
};

class AppBackgroundKaleido extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bgImg: "",
            logoSrc: logoEn160
        };

        if (props.storeThis) {
            props.storeThis("APP", this);
        }
    }

    handleResize = () => {
        const windowWidth = parseInt(window.innerWidth);
        let bgImg = "";
        if (windowWidth > 1019 && windowWidth < 1440) {
            bgImg = backgroundKaleidoTl;
        } else if (windowWidth > 449 && windowWidth < 1020) {
            bgImg = backgroundKaleidoTp;
        } else if (windowWidth < 450) {
            bgImg = backgroundKaleidoMb;
        } else if (windowWidth > 1439) {
            bgImg = backgroundKaleidoDt;
        }
        this.setState({ bgImg });
        return bgImg;
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        let logoSrc = "";
        switch (this.props.selectedLang) {
            case "zh-trd-HK":
                logoSrc = logoTc160;
                break;
            case "zh-sim-HK":
                logoSrc = logoSc160;
                break;
            case "en-HK":
                logoSrc = logoEn160;
                break;
            default:
                logoSrc = logoEn160;
                break;
        }
        this.setState({ logoSrc });
    }

    render() {
        return (
            <BackgroundKaleido selectedLang={this.props.selectedLang}>
                <div className="kaleido">
                    {/* <div className="logo-160">
                        <img src={logoTc160} alt="logoTc160" />
                    </div> */}
                    <div className="img-box">
                        <img src={this.state.bgImg} className="img-bg" />
                        {envConfig.realm === "HK" ? (
                            <img
                                src={this.state.logoSrc}
                                className="logo-160"
                                alt="logoTc160"
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </BackgroundKaleido>
        );
    }
}

const BackgroundKaleido = styled.div`
    // height: 100%;
    .kaleido {
        height: 100%;
        width: 50%;
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;

        .img-box {
            position: relative;
            max-height: 100%;
            overflow: auto;
        }

        .img-bg {
            width: 100%;
            max-height: 100%;
        }

        .logo-160 {
            position: absolute;
            bottom: 8px;
            width: 46%;
            z-index: 1;
            right: 0;
        }

        @media (min-width: 1440px) {
            .logo-160 {
                width: ${props =>
                    props.selectedLang === "en-HK" ? "50.139%" : "39.281%"};
            }
        }

        @media (min-width: 1020px) and (max-width: 1439px) {
            .logo-160 {
                width: ${props =>
                    props.selectedLang === "en-HK" ? "35%" : "27.42%"};
            }
        }

        @media (min-width: 450px) and (max-width: 1019px) {
            .logo-160 {
                width: ${props =>
                    props.selectedLang === "en-HK" ? "47%" : "36.821%"};
            }
        }

        @media (max-width: 449px) {
            width: 100%;
            .logo-160 {
                width: ${props =>
                    props.selectedLang === "en-HK" ? "60.834%" : "47.661%"};
            }
        }
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppBackgroundKaleido);

import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Notification } from "hsbc-pb-sdk";

import { color } from "util/color";
import SecurityModal from "components/common/SecurityModal";
import SecurityTextInput from "components/common/SecurityTextInput";
import WarningMsg from "components/common/WarningMsg";
import { resetPasswordActions } from "actions/resetPasswordAction";
import { usernameCommonValidate } from "util/validator";
import { logonActions } from "actions/logonAction";

const mapStateToProps = state => {
    return {
        error: state.resetPassword.error,
        disabledButton: state.resetPassword.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        storeUserName: username =>
            dispatch(resetPasswordActions.storeUserName(username)),
        isLoading: payload =>
            dispatch(resetPasswordActions.validateUsernameIsLoading(payload)),
        isLastAttemptTime: isLastAttempt => {
            dispatch(logonActions.isLastAttempt(isLastAttempt));
        }
    };
};

class ForgotPassword extends Component {
    constructor(props) {
        super();
        this.state = {
            username: "",
            usernameWarningMsg: null,
            isValidUsername: null,
            displayErrorMsg: false
        };
        if (props.storeThis) {
            props.storeThis("ForgotPassword", this);
        }
        window.tealiumHub.PT015();
    }
    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };
    validateInput = () => {
        this.setState(previousState => {
            return {
                ...previousState,
                usernameWarningMsg: usernameCommonValidate(this.state.username)
            };
        });
    };

    submitUsername = () => {
        this.validateInput();
        window.tealiumHub.ET033("forgotPassword.label.submit");
        if (!usernameCommonValidate(this.state.username)) {
            this.props.storeUserName(this.state.username);
            this.props.isLoading(true);

            this.props.invokeResetPasswordPolicy(
                this.state.username,
                this.handleUserNameResetPassword
            );
        }
    };

    failCallback = res => {
        this.setState(previousState => {
            return {
                ...previousState,
                usernameWarningMsg: res.statusText
            };
        });
    };

    handleUserNameResetPassword = () => {
        this.props.isLoading(false);
        this.setState({
            isValidUsername: false
        });
    };

    componentWillUnmount() {
        this.props.hideSelectOption(true);
        this.props.isLoading(false);
        this.setState({
            isValidUsername: null
        });
    }

    componentDidMount() {
        this.props.hideSelectOption(false);
        const urlParam = window.location.href.split("?")[1];
        let displayErrorMsg = false;
        if (urlParam && urlParam.toUpperCase() === "INVALIDCREDENTIALS") {
            displayErrorMsg = true;
        }
        this.setState({
            displayErrorMsg
        });
        this.props.isLastAttemptTime(false);
    }

    getNotification = () => {
        return (
            <div>
                <p>
                    {this.props.intl.formatMessage({
                        id: "enter.username.reset.pwd.warning.first"
                    })}
                </p>
                <p>
                    {this.props.intl.formatMessage({
                        id: "enter.username.reset.pwd.warning.second"
                    })}
                </p>
            </div>
        );
    };

    render() {
        const { usernameWarningMsg } = this.state;

        let usernameErrorComp = null;
        if (usernameWarningMsg !== null && usernameWarningMsg) {
            window.tealiumHub.ET034(this.state.usernameWarningMsg);
            usernameErrorComp = (
                <WarningMsg
                    id="username_error"
                    warningMsg={this.state.usernameWarningMsg}
                    plain={true}
                />
            );
            console.log(this.state.usernameWarningMsg);
        }
        let submitErrorComp = null;
        if (this.props.error === 500 || this.state.isValidUsername === false) {
            window.tealiumHub.ET034("forgotPassword.warning.notRecognised");
            submitErrorComp = (
                <WarningMsg
                    id="username_error"
                    warningMsg="forgotPassword.warning.notRecognised"
                />
            );
        }
        let retryComp = null;
        if (
            this.state.displayErrorMsg &&
            !usernameErrorComp &&
            !submitErrorComp
        ) {
            retryComp = (
                <DisplayLastAttamptContainer>
                    <Notification
                        error
                        inline
                        content={() => this.getNotification()}
                    />
                </DisplayLastAttamptContainer>
            );
        }
        return (
            <SecurityModal
                title="forgotPassword.title"
                confirmationBtnTitle="forgotPassword.label.submit"
                onConfirmationClick={this.submitUsername}
                ariaTitle="forgotPassword.ariaTitle"
                disabledButton={this.props.disabledButton || false}
            >
                <ForgotPasswordContainer>
                    <SecurityTextInput
                        type="text"
                        name="username"
                        hideLabel={false}
                        id="username"
                        ariaDescribedby="username_error"
                        label="inputCredential.label.username"
                        onBlur={this.validateInput}
                        onChange={this.handleChange}
                        errorComp={usernameErrorComp || submitErrorComp}
                        title="inputCredential.label.username"
                        onFocus={touched => {
                            if (!touched) {
                                window.tealiumHub.ET032("username");
                            }
                        }}
                    />
                </ForgotPasswordContainer>
                {retryComp}
            </SecurityModal>
        );
    }

    startSession = payload => {
        this.props.isLoading(false);
    };
    promiseFormInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    setPromiseHandlers(acceptFN, rejectFN) {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    }

    endSession() {
        console.log("endSession");
    }

    onContinue = payload => {
        this.props.isLoading(false);
        console.log(
            "Forgot Password :: onContinue ",
            this.constructor.name,
            payload
        );
        if (payload) {
            console.log("=====onContinue====", payload);
            this.props.isLoading(false);
            if (payload.reason === "USERNAME_DOES_NOT_EXIST") {
                this.failCallback({
                    errorStatus: 204,
                    statusText: this.props.intl.formatMessage({
                        id: "forgotPassword.warning.notRecognised"
                    })
                });
            }
        }
    };

    promiseRecoveryForError = () => {
        console.log("reset password username enter is invalid");
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
        );
    };

    onError = payload => {
        this.props.isLoading(false);
        console.log("onErwelcoror ", this.constructor.name, payload);
        window.globalHistory.push({
            pathname: "/globalError/noConnection?step=028",
            state: { errorCode: "noConnection" }
        });
    };
}
const ForgotPasswordContainer = styled.div`
    padding-bottom: 40px;
    .hsbc-ui.notification .content p {
        font-size: 14px;
    }
    p {
        line-height: 24px;
        margin-top: 30px;
        margin-bottom: 7px;
    }
    input {
        height: 40px;
        color: ${color.text_color_dark};
    }
`;
const DisplayLastAttamptContainer = styled.div.attrs({
    className: "last--attempt--reset--poassword--contianer"
})`
        margin-top:-15px;
        margin-bottom: 20px;
        .hsbc-ui.notification.inline{
            padding:0px !important; 
            p{
                font-size:14px;
            }
        }
   }
  `;
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ForgotPassword));

import { history } from "util/history";
import { envConfig } from "../env-config";
import JsEncrypt from "jsencrypt/bin/jsencrypt.min.js";

export function isTextSelected() {
    let selecttxt = "";
    if (window.getSelection) {
        selecttxt = window.getSelection();
    } else if (document.getSelection) {
        selecttxt = document.getSelection();
    } else if (document.selection) {
        selecttxt = document.selection.createRange().text;
    }
    return !!selecttxt;
}

export function unSupportedBrowsers() {
    if (!!window.ActiveXObject) {
        const b = document.createElement("b");
        b.innerHTML = "<!--[if IE 6]><i></i><![endif]-->";
        const isIE6 = b.getElementsByTagName("i").length === 1;
        if (isIE6) {
            history.push("/unsupportedBrowser");
            return;
        }
    }
}

export function switchselectedLangForDocTitle(title) {
    switch (title) {
        case "zh-sim-HK":
            document.title = "环球私人银行用户注册和登录";
            break;
        case "zh-trd-HK":
            document.title = "環球私人銀行用戶登記及登入";
            break;
        case "en-LU":
        case "fr-LU":
            document.title =
                "Enregistrement et connexion des utilisateurs de Global Private Banking";
            break;
        default:
            document.title =
                "Global Private Banking User Registration and Login";
    }
}

let initializedTransmitSdkPromise;
export function promiseInitializedTransmitSdk() {
    if (!initializedTransmitSdkPromise) {
        initializedTransmitSdkPromise = new Promise((accept, reject) => {
            window.requirejs(["XmSdk"], function(xm) {
                const sdk = xm.XmSdk();
                const endpoint = envConfig.transmitHost;
                const appId = envConfig.appId;
                const apiTokenId = envConfig.apiTokenId;
                const apiToken = envConfig.apiToken;
                const connectionSettings = window.com.ts.mobile.sdk.SDKConnectionSettings.create(
                    endpoint,
                    appId,
                    apiTokenId,
                    apiToken
                );

                connectionSettings.setRealm(envConfig.realm);

                if (envConfig.crypto) {
                    connectionSettings.setCryptoMode(envConfig.cryptoMode);
                }
                sdk.setConnectionSettings(connectionSettings);
                sdk.setLocale(envConfig.locale);

                window.transmitSDK = sdk;
                sdk.initialize().then(function() {
                    accept(sdk);
                });
            });
        });
    }
    return initializedTransmitSdkPromise;
}

promiseInitializedTransmitSdk();

let resetIdleTimeOut;
sessionStorage.setItem("idleTimeOutValid", false);
function resetIdle(e, time = 10 * 60 * 1000) {
    if (resetIdleTimeOut) {
        clearTimeout(resetIdleTimeOut);
    }
    resetIdleTimeOut = setTimeout(() => {
        const idleTimeOutValid = sessionStorage.getItem("idleTimeOutValid");
        console.log(
            "Idle time's up, will jump to logon?",
            idleTimeOutValid,
            "current time is:",
            new Date()
        );
        if (idleTimeOutValid !== "false") {
            history.push("/");
            history.push("/logon?forcedLogout");
            sessionStorage.setItem("idleTimeOutValid", false);
        }
        resetIdle();
    }, time);
}
export function changeIdleTimeOutValid(bool) {
    sessionStorage.setItem("idleTimeOutValid", bool);
}

function registeIdleTimeOut() {
    window.addEventListener("mousemove", resetIdle, true);
    window.addEventListener("scroll", resetIdle, true);
    window.addEventListener("mousedown", resetIdle, true);
    window.addEventListener("touchstart", resetIdle, true);
    window.addEventListener("click", resetIdle, true);
    window.addEventListener("keypress", resetIdle, true);
}
registeIdleTimeOut();

export function encryptPassword(password, publicKey) {
    const jse = new JsEncrypt();
    jse.setPublicKey(publicKey);
    return jse.encrypt(password);
}

export function handleMaskNumber(str) {
    if (!str) {
        return null;
    }
    str = str.replace(/\*+/, "masked ");

    str.split("").forEach(function(char, i) {
        if (!isNaN(char)) {
            str = str.slice(0, i) + " " + str.slice(i, str.length);
        }
    });
    return str;
}

export function guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        const crypto = window.crypto || window.msCrypto;
        let arr2 = new Uint32Array(32);
        let r = (crypto.getRandomValues(arr2)[0] * Math.pow(2, -28)) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

import React, { Component } from "react";
import styled from "styled-components";
import SecurityModal from "components/common/SecurityModal";
import SecurityTextInput from "components/common/SecurityTextInput";
import { connect } from "react-redux";
import { createUserNameActions } from "actions/createUserNameAction";
import _ from "lodash";
import { usernameCommonValidate, createPasswordValidate } from "util/validator";
import { FormattedMessage, injectIntl } from "react-intl";
import WarningMsg from "components/common/WarningMsg";
import { encryptPassword } from "util/service";

const mapStateToProps = state => {
    return {
        terms: state.logon.terms,
        phoneindex: _.get(state.currentOTPType, "target._channelIndex", false),
        isLoading: state.createUsername.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        disableCreateUsernameBtn: payload =>
            dispatch(createUserNameActions.createUserNameIsLoading(payload))
    };
};

class CreateUsernamePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            firstPassword: "",
            secondPassword: "",
            usernamelegalMsg: "",
            firstPasswordlegalMsg: "",
            secondPasswordlegalMsg: ""
        };
        this.firstPasswordComp = null;
        this.secondPasswordComp = null;
        this.usernameComp = null;
        this.errorShowType = "";
        if (props.storeThis) {
            props.storeThis("CreateUsernamePassword", this);
        }
    }

    componentDidMount() {
        window.tealiumHub.PT012();
    }

    submit = () => {
        window.tealiumHub.ET024("registration.submit");
        const { firstPassword } = this.state;
        const firstPasswordlegalMsg = this.validateFirstInput();
        const secondPasswordlegalMsg = this.validateSecondInput();
        const encrypted =
            this.e2eState === "enabled"
                ? encryptPassword(firstPassword, this.e2ePublicKey)
                : firstPassword;
        if (!secondPasswordlegalMsg && !firstPasswordlegalMsg) {
            const formInput = window.com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
                {
                    password: encrypted
                }
            );
            this.props.disableCreateUsernameBtn(true);
            this.acceptFN(formInput);
        }
    };

    handleChange = event => {
        const { target } = event;
        const { value, name } = target;
        this.setState((previousState, currentProps) => {
            return {
                ...previousState,
                [name]: value,
                [`${name}legalMsg`]: ""
            };
        });
    };

    validateUsernameInput = () => {
        this.errorShowType = "";
        const { username } = this.state;
        const validateResult = usernameCommonValidate(username, "username");
        this.setState(previousState => {
            return {
                ...previousState,
                usernamelegalMsg: validateResult
            };
        });
        if (validateResult) {
            this.usernameComp.clearValue();
        }
        return validateResult;
    };

    validateFirstInput = () => {
        const { firstPassword } = this.state;
        const firstPasswordlegalMsg = createPasswordValidate(firstPassword, {
            userName: this.state.username
        });
        this.setState(previousState => {
            return { ...previousState, firstPasswordlegalMsg };
        });
        return firstPasswordlegalMsg;
    };

    validateSecondInput = () => {
        const isSame = this.state.secondPassword === this.state.firstPassword;
        const newfirstPassword = isSame ? this.state.firstPassword : "";
        const newSecondPassword = isSame ? this.state.secondPassword : "";
        const secondMsg = isSame
            ? ""
            : "createUsernamePassword.warning.secondMsg";
        if (!isSame) {
            this.firstPasswordComp.clearValue();
            this.secondPasswordComp.clearValue();
            this.firstPasswordComp.getSelfFocused();
        }
        const secondPasswordlegalMsg = secondMsg;
        this.setState({
            secondPasswordlegalMsg: secondMsg,
            firstPassword: newfirstPassword,
            secondPassword: newSecondPassword
        });

        return secondPasswordlegalMsg;
    };

    submitUserName = () => {
        const { username } = this.state;
        const usernamelegalMsg = this.validateUsernameInput();
        if (usernamelegalMsg) {
            return;
        }
        const { phoneindex = 0 } = this.props;
        const formInput = window.com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
            {
                alias: username.toLowerCase(),
                phoneindex
            }
        );
        this.props.disableCreateUsernameBtn(true);
        this.acceptFN(formInput);
    };

    getWarningMsg = (msg, ids, errorType) => {
        window.tealiumHub.ET025(msg);
        return (
            <WarningMsg
                id={ids}
                warningMsg={msg}
                type={errorType ? this.errorShowType : ""}
            />
        );
    };

    getElement = () => {
        return (
            <div>
                <h1 className="create--password--header createUsernamePassword-firstpassword-label">
                    <FormattedMessage id="createUsernamePassword.label.createPassword" />
                </h1>

                <p className="create-username-password-content-p1">
                    <label
                        className="label-style"
                        htmlFor="firstPassword"
                        aria-label={this.props.intl.formatMessage({
                            id:
                                "createUsernamePassword.aria.label.firstPassword"
                        })}
                    >
                        <span aria-hidden="true">
                            {this.props.intl.formatMessage({
                                id: "createUsernamePassword.label.firstPassword"
                            })}
                        </span>
                    </label>
                </p>

                <p className="create-username-password-content-p2">
                    <label
                        className="label-style"
                        htmlFor="firstPassword"
                        aria-label={this.props.intl.formatMessage({
                            id:
                                "createUsernamePassword.aria.label.firstPassword.additional"
                        })}
                    >
                        <span aria-hidden="true">
                            {this.props.intl.formatMessage({
                                id:
                                    "createUsernamePassword.aria.label.firstPassword.additional"
                            })}
                        </span>
                    </label>
                    <p className="create-username-password-content-p2-child">
                        <label>
                            <ul className="create-username-password-content-p2-child-list">
                                <li
                                    aria-label={this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content1"
                                    })}
                                >
                                    {this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content1"
                                    })}
                                </li>
                                <li
                                    aria-label={this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content2"
                                    })}
                                >
                                    {this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content2"
                                    })}
                                </li>
                                <li
                                    aria-label={this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content3"
                                    })}
                                >
                                    {this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content3"
                                    })}
                                </li>
                                <li
                                    aria-label={this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content4"
                                    })}
                                >
                                    {this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content4"
                                    })}
                                </li>
                                <li
                                    aria-label={this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content5"
                                    })}
                                >
                                    {this.props.intl.formatMessage({
                                        id:
                                            "createUsernamePassword.aria.label.firstPassword.additional.content5"
                                    })}
                                </li>
                            </ul>
                        </label>
                    </p>
                </p>
            </div>
        );
    };

    getUsernameCom = () => {
        let usernameErrorComp = null;
        if (this.state.usernamelegalMsg) {
            usernameErrorComp = this.getWarningMsg(
                this.state.usernamelegalMsg,
                "username_error",
                true
            );
        }
        return (
            <SecurityTextInput
                type="text"
                name="username"
                label="createUsernamePassword.label.username"
                hideLabel={false}
                id="username"
                ariaDescribedby="username_error"
                customClassName="create-username-password-input"
                title="createUsernamePassword.label.username"
                onChange={this.handleChange}
                errorComp={usernameErrorComp}
                onRef={comp => (this.usernameComp = comp)}
                disabled={this.state.shouldShowPasswordPasrt}
                onFocus={touched => {
                    if (!touched) {
                        window.tealiumHub.ET023("username");
                    }
                }}
            />
        );
    };

    getFirstPasswordCom = () => {
        let firstErrorComp = null;
        if (this.state.firstPasswordlegalMsg) {
            firstErrorComp = this.getWarningMsg(
                this.state.firstPasswordlegalMsg,
                "firstPassword_error",
                false
            );
        }
        return (
            <SecurityTextInput
                type="password"
                isPswInput={true}
                customClassName="create-username-password-input"
                onChange={this.handleChange}
                id="firstPassword"
                ariaDescribedby="firstPassword_error"
                title="createUsernamePassword.input.firstPasswordTitle"
                name="firstPassword"
                label="createUsernamePassword.label.firstPassword"
                hideLabel={true}
                errorComp={firstErrorComp}
                onRef={comp => (this.firstPasswordComp = comp)}
                onFocus={touched => {
                    if (!touched) {
                        window.tealiumHub.ET023("password");
                    }
                }}
            />
        );
    };

    getSecondPasswordCom = () => {
        let secondErrorComp = null;
        if (this.state.secondPasswordlegalMsg) {
            secondErrorComp = this.getWarningMsg(
                this.state.secondPasswordlegalMsg,
                "secondPassword_error",
                false
            );
        }

        return (
            <SecurityTextInput
                type="password"
                isPswInput={true}
                customClassName="create-username-password-input"
                onChange={this.handleChange}
                ariaDescribedby="secondPassword_error"
                title="createUsernamePassword.label.RePassword"
                name="secondPassword"
                label="createUsernamePassword.label.RePassword"
                hideLabel={false}
                id="secondPassword"
                errorComp={secondErrorComp}
                onRef={comp => (this.secondPasswordComp = comp)}
                onFocus={touched => {
                    if (!touched) {
                        window.tealiumHub.ET023("reenter password");
                    }
                }}
            />
        );
    };

    getWarningMsgCom = () => {
        let userWarningMsgErrorComp = null;
        if (this.state.userWarningMsg) {
            userWarningMsgErrorComp = this.getWarningMsg(
                this.state.userWarningMsg,
                "user_error",
                true
            );
        }
        return userWarningMsgErrorComp;
    };

    render() {
        const { shouldShowPasswordPasrt } = this.state;

        return (
            <CreateUsernamePasswordContainer>
                <SecurityModal
                    title="createUsernamePassword.title"
                    ariaTitle="createUsernamePassword.title"
                    onConfirmationClick={
                        shouldShowPasswordPasrt ||
                        this.props.testShouldShowPasswordPasrt
                            ? this.submit
                            : this.submitUserName
                    }
                    confirmationBtnTitle="registration.submit"
                    disabledButton={this.props.isLoading || false}
                >
                    <div className="create-username-password-content">
                        <p
                            className="create-username-password-content-p1"
                            aria-label={this.props.intl.formatMessage({
                                id: "createUsernamePassword.aria.label.subTitle"
                            })}
                        >
                            <span aria-hidden="true">
                                {this.props.intl.formatMessage({
                                    id: "createUsernamePassword.label.subTitle"
                                })}
                            </span>
                        </p>
                        {this.getUsernameCom()}
                        {this.state.shouldShowPasswordPasrt ||
                        this.props.testShouldShowPasswordPasrt ? (
                            <div>
                                {this.getElement()}
                                {this.getFirstPasswordCom()}
                                {this.getSecondPasswordCom()}
                                <div>{this.getWarningMsgCom()}</div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </SecurityModal>
            </CreateUsernamePasswordContainer>
        );
    }

    e2ePublicKey = null;
    startSession = payload => {
        this.props.disableCreateUsernameBtn(false);
        if (this.state.shouldShowPasswordPasrt === false) {
            if (payload.jsonData) {
                this.e2ePublicKey = payload.jsonData.e2ePublicKey;
                this.e2eState = payload.jsonData.E2EE_State;
                if (this.e2eState === "enabled" && !this.e2ePublicKey) {
                    window.globalHistory.push({
                        pathname: "/globalError/noConnection?step=018",
                        state: { errorCode: "noConnection" }
                    });
                }
            } else {
                window.globalHistory.push({
                    pathname: "/globalError/noConnection?step=019",
                    state: { errorCode: "noConnection" }
                });
            }
            this.setState({
                shouldShowPasswordPasrt: true
            });
        } else {
            this.setState({
                shouldShowPasswordPasrt: false
            });
        }
    };

    endSession() {
        console.log("endSession");
    }

    setUsernameMsg = () => {
        this.setState(previousState => {
            return {
                ...previousState,
                usernamelegalMsg:
                    "createUsernamePassword.warning.usernamelegalMsg"
            };
        });
    };

    onContinue = payload => {
        this.props.disableCreateUsernameBtn(false);
        if (payload.status === "DUPLICATE_USERNAME") {
            this.errorShowType = "yellow";
            this.setUsernameMsg();
        }
        if (payload.status === "INCORRECT_PASSWORD_FORMAT") {
            this.errorShowType = "yellow";
            this.setState(previousState => {
                return {
                    ...previousState,
                    firstPasswordlegalMsg:
                        "createUsernamePassword.warning.incorrectPasswordFormat"
                };
            });
        }
    };

    onError = payload => {
        this.props.disableCreateUsernameBtn(false);
        if (!payload) {
            window.globalHistory.push({
                pathname: "/globalError/noConnection?step=020",
                state: { errorCode: "noConnection" }
            });
        }
        if (payload.status === "ALREADY_REGISTERED") {
            this.setUsernameMsg();
        } else if (payload.status === "INCORRECT_USERNAME_FORMAT") {
            this.setState(previousState => {
                return {
                    ...previousState,
                    usernamelegalMsg:
                        "createUsernamePassword.warning.incorrectUserNameFormat" // to be changed
                };
            });
        } else {
            window.globalHistory.push({
                pathname: "/globalError/noConnection?step=021",
                state: { errorCode: "noConnection" }
            });
        }
    };
    promiseFormInput = payload => {
        return new Promise((accept, reject) => {
            this.setPromiseHandlers(accept, reject);
        });
    };

    setPromiseHandlers = (acceptFN, rejectFN) => {
        this.acceptFN = acceptFN;
        this.rejectFN = rejectFN;
    };
    promiseRecoveryForError = () => {
        return Promise.resolve(
            window.com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail
        );
    };
}

const CreateUsernamePasswordContainer = styled.div`
    .contentmodal--content {
        .create-username-password-content {
            .create-username-password-input-label {
                margin-top: 20px;
                font-size: 16px;
                line-height: 19px;
            }
            .create-username-password-input {
                margin-top: 10px;
                input {
                    width: 230px;
                }
            }
            .createUsernamePassword-firstpassword-label {
                margin-top: 50px;
            }
            .create--password--header {
                font-size: 28px;
                font-weight: 100;
                line-height: 36px;
            }
        }
        .create-username-password-content-p1 {
            margin-top: 30px;
            color: #252525;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
        }

        .create-username-password-content-p2 {
            margin-top: -5px;
            color: #252525;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            &-child {
                margin-top: 0px;
                &-list {
                    margin-top: ;
                }
            }
        }
    }
    p {
        margin-bottom: 10px;
    }
    .content-main {
        font-weight: 300;
        line-height: 24px;
        padding-top: 30px;
        padding-bottom: 10px;
    }
    .content-repassword {
        font-weight: normal;
        padding-top: 15px;
    }
    .contentmodal--footer {
        margin-top: 30px;
    }
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CreateUsernamePassword));
